define(['app', 'componentHelper'], function(app, componentHelper) {


  const pap = function() {
    const component = {};
    const SHOW_PAP_BANNER_CLASS = 'showPapBanner';

    const _config = {
      channels: {
        update: 'pap/update'
      }
    };

    const _init = (element, userDriven) => {
      component.element = element;

      if (!userDriven) {
        component.subscribe();
      }

      return component;
    };

    const _subscribe = () => {
      app.subscribe(component.config.channels.update, component.update);
    };

    const _update = (productId) => {
      app.ajax.get({
        url: `/${productId}.pap`,
        success: component.successHandler,
        error: component.errorHandler
      });
    };

    const _successHandler = (response) => {
      let parentNode = component.element.parentNode;
      if (parentNode) {
        if (response === '') {
          parentNode.classList.remove(SHOW_PAP_BANNER_CLASS);
        } else {
          if (!parentNode.classList.contains(SHOW_PAP_BANNER_CLASS)) {
            parentNode.classList.add(SHOW_PAP_BANNER_CLASS)
          }
        }
      }

      component.element.innerHTML = response;
      componentHelper.reloadAllComponents(component.element);
    };

    const _errorHandler = () => {
      console.error(
        'ERROR: Could not retrieve new pap description');
    };


    component.config = _config;
    component.init = _init;
    component.subscribe = _subscribe;
    component.update = _update;
    component.successHandler = _successHandler;
    component.errorHandler = _errorHandler;
    return component;
  };

  return pap;
});
